import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';

const currentMembers = [
  {
    name: 'Mahamed Abdalla',
    position: 'President of SISBC',
  },
  {
    name: 'Muhyadeen Jama',
    position: 'Vice-President of SISBC',
  },
  {
    name: 'Abdisalam Abdulle',
    position: 'Secretary',
  },
  {
    name: 'Khalif Mahamed',
    position: 'Treasurer',
  },
  {
    name: 'Abiib Kamil',
    position: 'Board Member',
  },
  {
    name: 'Muhaydeen Ahmed Haybe',
    position: 'Board Member',
  },
  {
    name: 'Mahamoud Omer Mohamoud',
    position: 'Board Member',
  },
];

const boardOfTrusteeMember = 'Board of Trustee Member';
const boardOfTrustees = [
  {
    name: 'Abdirizaq Barqat',
    position: 'President of the Board of Trustees',
  },
  {
    name: 'Daud Diis',
    position: boardOfTrusteeMember,
  },
  {
    name: 'Mohamed Yusuf',
    position: boardOfTrusteeMember,
  },
  {
    name: 'Abdiweli A. Omar',
    position: boardOfTrusteeMember,
  },
  {
    name: 'Siciid Mohamud',
    position: boardOfTrusteeMember,
  },
  {
    name: 'Omar Ali',
    position: boardOfTrusteeMember,
  },
  {
    name: 'Amin Cosoble',
    position: boardOfTrusteeMember,
  },
];

const MemberTemplate = (data) => {
  const { memberList, sectionId, sectionTitle } = data;
  return (
    <section id={sectionId} className="py-20 lg:pb-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">{sectionTitle}</h2>
        <div className="flex flex-wrap -mx-4 overflow-hidden sm:-mx-2 xl:-mx-3">
          {memberList.map((person, index) => (
            <div
              key={index}
              className="my-4 px-4 w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3"
            >
              <Card className="mb-8">
                <p className="font-semibold text-xl">{person.name}</p>
                <p className="mt-4">{person.position}</p>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Index = () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-3xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Somali Islamic Society of BC
          </h1>
          {/* <p className="text-xl lg:text-2xl mt-6 font-light">Upcoming election very soon</p> */}
          <p className="mt-8 md:mt-12">
            <Button size="lg">Become a SISBC Member</Button>
            {/* <div className="py-10 lg:pb-10 font-semibold text-xl">
              Accepting Membership Applications until Friday, March 31, 2023
              <div className="font-semibold text-xl">
                Next AGM meeting is Sunday, November 06th @ 12:30 PM
              </div>
            </div> */}
          </p>
          <div></div>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>

    <MemberTemplate
      memberList={currentMembers}
      sectionId={'features'}
      sectionTitle={'Board Members'}
    />
    <MemberTemplate
      memberList={boardOfTrustees}
      sectionId={'services'}
      sectionTitle={'Board of Trustees'}
    />

    {/* <SplitSection
      id="services"
      primarySlot={
        // <div className="lg:pr-32 xl:pr-48">
        //   <h3 className="text-3xl font-semibold leading-tight">Board of Trustees</h3>
        //   <p className="mt-8 text-xl font-light leading-relaxed">
        //    List of candidates
        //   </p>
        // </div>
        <div>
              <MemberTemplate memberList={currentMembers} sectionId={'features'} sectionTitle={'Board Members'}/>
        </div>
      }
    /> */}
    {/* <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Design And Plan Your Business Growth Steps
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Once the market analysis process is completed our staff will search for opportunities
            that are in reach
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    /> */}
    {/* <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Search For Performance Optimization
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            With all the information in place you will be presented with an action plan that your
            company needs to follow
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    /> */}
    {/* <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section> */}
    {/* <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map((customer) => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section> */}
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Ready to become a SISBC member?</h3>
      {/* <p className="mt-8 text-xl font-light">
        Quis lectus nulla at volutpat diam ut. Enim lobortis scelerisque fermentum dui faucibus in.
      </p> */}
      <p className="mt-8">
        <Button size="xl">Become a SISBC Member</Button>
      </p>
    </section>
  </Layout>
);

export default Index;
